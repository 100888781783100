var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"import-archive","bladeName":"import-archives","bladesData":_vm.bladesData,"canSearchLocal":"","headers":[
        // { text: 'Transfer', value: 'isTransfer', bool: true },
        { text: 'Date', value: 'performedOn', textFilter: 'toShortDateAndTime' },
        { text: 'Departure', value: 'departureLocationID', display: true },
        { text: 'Destination', value: 'destinationLocationID', display: true }],"navigation":"import-archives","title":"Import Archives","useServerPagination":""},scopedSlots:_vm._u([{key:"departureLocationID",fn:function(ref){
        var item = ref.item;
return [_c('BT-Entity',{attrs:{"itemValue":item.departureLocationID,"navigation":"public-locations","single":"","textFilter":"toFamiliarLocationLine","useLocalCache":""}})]}},{key:"destinationLocationID",fn:function(ref){
        var item = ref.item;
return [_c('BT-Entity',{attrs:{"itemValue":item.destinationLocationID,"navigation":"public-locations","single":"","textFilter":"toFamiliarLocationLine","useLocalCache":""}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }