<template>
    <BT-Blade-Items
        addBladeName="import-archive"
        bladeName="import-archives"
        :bladesData="bladesData"
        canSearchLocal
        :headers="[
            // { text: 'Transfer', value: 'isTransfer', bool: true },
            { text: 'Date', value: 'performedOn', textFilter: 'toShortDateAndTime' },
            { text: 'Departure', value: 'departureLocationID', display: true },
            { text: 'Destination', value: 'destinationLocationID', display: true }]"
        navigation="import-archives"
        title="Import Archives"
        useServerPagination>
        <template v-slot:departureLocationID="{ item }">
            <BT-Entity
                :itemValue="item.departureLocationID"
                navigation="public-locations"
                single
                textFilter="toFamiliarLocationLine"
                useLocalCache />
        </template>
        <template v-slot:destinationLocationID="{ item }">
            <BT-Entity
                :itemValue="item.destinationLocationID"
                navigation="public-locations"
                single
                textFilter="toFamiliarLocationLine"
                useLocalCache />
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Import-Archives',
    props: {
        bladesData: null
    }
}
</script>